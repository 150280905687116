/* FloatingButton.css */
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #35495e;
    color: white;
    padding: 10px;
    width: 40px; /* Set width and height to make it square */
    height: 40px;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border-radius: 5px; /* You can adjust the border radius to control the corners */
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .floating-button:hover {
    background-color: #0056b3;
  }
  
  /* Style the icon (assuming you're using font-awesome) */
  .floating-button i {
    font-size: 20px;
  }
  